<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style="height: 395px">
        <field name="delegacion_id" widget="m2o" label="Deleg." width="165px" style="top: 9px; left: 10px"
          :readonly="mode == 'edit'" />
        <field name="ndoc" widget="char" searchable label="Nº Documento" width="110px" style="top: 9px; left: 189px" />
        <field name="fecha" widget="date" type="date" default="today" label="Fecha" width="100px"
          style="top: 9px; left: 307px" />
        <field name="almacen_id" widget="m2o" label="Almacen" width="235px" style="top: 9px; left: 415px"
          :readonly="mode == 'edit'" :filter="formData.delegacion_id
            ? [['delegacion_id', '=', formData.delegacion_id.codigo]]
            : null
            " />
        <r-tabs ref="main-tabs" :wt="formData.wt" style="top: 55px; height: 340px" :buttons="['General', 'Líneas']">
          <div class="tab">
            <fieldset style="
                position: relative;
                left: 10px;
                top: 20px;
                width: 260px;
                height: 59px;
              ">
              <legend>Tipo de regularización</legend>
              <field name="tipo" widget="radio" :options="{
                'Entrada de género': 'entrada',
                'Salida de género': 'salida',
              }" inline-label style="top: 6px; left: 20px" />
            </fieldset>
            <field name="observaciones" widget="text" label="Observaciones" width="636px" height="100px"
              style="top: 100px; right: 10px" />
          </div>
          <div class="tab">
            <field ref="lineas" name="lineas" widget="handsontable" :height="295" :width="655" :minRows="12"
              style="top: 0px; left: 0px; width: 660px" customprop="docs_customprop" :readonly="mode == 'edit'" :columns="[
                { name: 'ean13', header: '|||||||||' },
                {
                  name: 'articulo_id',
                  header: 'Cód.',
                  noOpen: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                  showCode: false,
                },
                { name: 'descripcion', header: 'Artículo', readOnly: true },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true,
                },
              ]" />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import Handsontable from "handsontable";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Albarán de regularización",
      dbAdapter: "albaran_regularizacion",
      primary: "ndoc",
      sequence: { name: "albaran_regularizacion" },
      defaultData: {
        delegacion_id: self.app.config.delegacion_defecto || null,
        //almacen_id: self.app.config.almacen_defecto || null,
        fecha: new Date().yyyymmdd(),
        wt: 0,
      },
      fields: ["wt"],
      cachedArticulosCodigo: {},
      cachedArticulosEan13: {},
    };
  },
  methods: {},
  mounted() {
    var self = this;

    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function (e) {
      if (self.app.session.mode == "a") return;
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "edit" && self.mode != "new" && self.mode != "search") return;
        self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
        self.$emit("change", "wt", "edit");
        if (self.mode == "search") self.setFilter(self.getFilter());
      }
    });

    self.$refs.lineas.field.hotInstance.addHook(
      "afterChange",
      function (changes, source) {
        if (source == "loadData") return;
        if (!changes) return;
        var h = this;
        changes.forEach(function (change, index) {
          var row = change[0];
          var prop = change[1];
          var oldVal = change[2];
          var newVal = change[3];
          let val = (c) => h.getDataAtRowProp(row, c) || 0;

          if (
            (prop == "ean13" || prop == "articulo_id") &&
            newVal &&
            oldVal != newVal &&
            source != "auto"
          ) {
            if (prop == "ean13" && newVal in self.cachedArticulosEan13) {
              var p = Promise.resolve({
                count: 1,
                data: [
                  self.$utils.misc.clone(self.cachedArticulosEan13[newVal]),
                ],
              });
            } else if (
              prop == "articulo_id" &&
              newVal in self.cachedArticulosCodigo
            ) {
              p = Promise.resolve({
                count: 1,
                data: [
                  self.$utils.misc.clone(self.cachedArticulosCodigo[newVal]),
                ],
              });
            } else {
              p = window.DB.getList("articulo", {
                limit: 1,
                filter: [
                  prop == "articulo_id" ? "codigo" : "ean13",
                  "=",
                  newVal,
                ],
                depth: 0,
                fields: [
                  "codigo",
                  "ean13",
                  "nombre",
                  "coste_unidad_venta",
                  "peso_unitario",
                ],
              });
            }
            p.then(function (res) {
              if (parseInt(res.count)) {
                let a = res.data[0];
                self.cachedArticulosCodigo[a.codigo] = a;
                self.cachedArticulosEan13[a.ean13] = a;

                let d = h.getSourceData()[row];
                d.articulo_id = a;
                d.ean13 = a.ean13;
                d.descripcion = a.nombre;
                d.unidades = 1;
                d.peso = parseFloat(a.peso_unitario || 0);
                d.precio = parseFloat(a.coste_unidad_venta || 0);

                self.renderH(h);
              } else {
                self.app.toast("No se han encontrado el artículo", "error");
                h.setDataAtRowProp(row, prop, oldVal, "auto");
              }
            }).catch(function (res) {
              window.console.log(res);
            });
          }

          if (prop == "unidades" && oldVal != newVal) {
            let d = h.getSourceData()[row];
            if (!newVal || isNaN(newVal)) d.unidades = 1;
            d.precio = (d.precio * d.unidades) / oldVal;
            d.peso = (d.peso * d.unidades) / oldVal;
          }
        });
      }
    );

    self.$refs.lineas.field.hotInstance.addHook("beforeKeyDown", function (e) {
      var keyCode = e.keyCode || e.which;
      var arrow = { left: 37, up: 38, right: 39, down: 40, tab: 9 };
      switch (keyCode) {
        case arrow.left:
        case arrow.up:
        case arrow.right:
        case arrow.down:
        case arrow.tab:
          return;
      }
      var h = this;
      var sel = h.getSelected();
      if (typeof sel == "undefined") return;
      var r = sel[0][0];
      var p = h.colToProp(sel[0][1]);
      if (
        p != "ean13" &&
        p != "articulo_id" &&
        !h.getDataAtRowProp(r, "articulo_id")
      ) {
        h.selectCell(r, "articulo_id");
      }
    });

    self.renderH = self.$utils.misc.debounce(function (h) {
      self.$refs.lineas.field.hotInstance.render();
    }, 200);
  },
};
</script>