var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form'),_c('section',[_c('article',{staticStyle:{"height":"395px"}},[_c('field',{staticStyle:{"top":"9px","left":"10px"},attrs:{"name":"delegacion_id","widget":"m2o","label":"Deleg.","width":"165px","readonly":_vm.mode == 'edit'}}),_c('field',{staticStyle:{"top":"9px","left":"189px"},attrs:{"name":"ndoc","widget":"char","searchable":"","label":"Nº Documento","width":"110px"}}),_c('field',{staticStyle:{"top":"9px","left":"307px"},attrs:{"name":"fecha","widget":"date","type":"date","default":"today","label":"Fecha","width":"100px"}}),_c('field',{staticStyle:{"top":"9px","left":"415px"},attrs:{"name":"almacen_id","widget":"m2o","label":"Almacen","width":"235px","readonly":_vm.mode == 'edit',"filter":_vm.formData.delegacion_id
          ? [['delegacion_id', '=', _vm.formData.delegacion_id.codigo]]
          : null}}),_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"55px","height":"340px"},attrs:{"wt":_vm.formData.wt,"buttons":['General', 'Líneas']}},[_c('div',{staticClass:"tab"},[_c('fieldset',{staticStyle:{"position":"relative","left":"10px","top":"20px","width":"260px","height":"59px"}},[_c('legend',[_vm._v("Tipo de regularización")]),_c('field',{staticStyle:{"top":"6px","left":"20px"},attrs:{"name":"tipo","widget":"radio","options":{
              'Entrada de género': 'entrada',
              'Salida de género': 'salida',
            },"inline-label":""}})],1),_c('field',{staticStyle:{"top":"100px","right":"10px"},attrs:{"name":"observaciones","widget":"text","label":"Observaciones","width":"636px","height":"100px"}})],1),_c('div',{staticClass:"tab"},[_c('field',{ref:"lineas",staticStyle:{"top":"0px","left":"0px","width":"660px"},attrs:{"name":"lineas","widget":"handsontable","height":295,"width":655,"minRows":12,"customprop":"docs_customprop","readonly":_vm.mode == 'edit',"columns":[
              { name: 'ean13', header: '|||||||||' },
              {
                name: 'articulo_id',
                header: 'Cód.',
                noOpen: true,
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
                showCode: false,
              },
              { name: 'descripcion', header: 'Artículo', readOnly: true },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                readOnly: true,
              } ]}})],1)])],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_c('div',{staticClass:"item-title"},[_vm._v("Listado")]),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }